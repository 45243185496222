// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

import Video from '@bodhi-project/components/lib/Video'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import K12SchoolPageWrapper from '../components/wrappers/K12SchoolPageWrapper'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Why us? | Learning Wings Education System',
  nakedPageSlug: 'about',
  pageAbstract:
    "Learning Wings Education System is a leading provider of school management services and works closely with schools to raise student achievements. It's flagship schools – Cambridge International School – spread across North India is showing children the process of realizing their potential.",
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <K12SchoolPageWrapper className="page" seoData={seoData} {...props}>
    <h1 class="shadow" data-shadow="Why us?">
      Why us?
    </h1>
    <div style={{ maxWidth: '50rem' }}>
      <Video
        url="https://www.youtube.com/watch?v=HJSpSzc2MQ0"
        style={{ marginBottom: 30 }}
      />
      <h2 className="mask-h4">Teachers</h2>
      <p>
        Cambridge teachers are competent as they are well equipped with the
        latest techniques and methods of teaching learning process. They take up
        the challenging tasks inside and outside the classroom and help the
        students to gain confidence and overcome the obstacles in their learning
        process. The communal hard work of the teachers acts as a driving force
        by which the goals and objectives of teaching learning process is
        achieved.
      </p>
      <h2 className="mask-h4">Modern Classrooms</h2>
      <p>
        Cambridges' Modern Classroom is a productive environment in which
        students can develop the skills they will require in the workplace and
        teachers are facilitators of their learning. The focus of these
        classrooms is on students, experiencing the environment, they will enter
        as modern day workers and developing their higher order thinking skills,
        effective communication skills, collaboration skills, making them adapt
        with using technology and all other skills in their day to day life.
      </p>
      <h2 className="mask-h4">Innovative Pedagogy</h2>
      <p>
        Cambridge believes in providing education in real sense. It is a temple
        of learning, where students are taught how to think and not what to
        think. Its interactive teaching - learning pedagogy has drawn
        appreciation from all quarters. Uniqueness of Cambridge lies in
        providing wholesome education to the students. It is an institution,
        where paramount importance is given to weaving life skills like self
        awareness, critical thinking, creative thinking, coping with stress,
        empathy, decision making, problem solving and effective communication in
        the curriculum. A challenging platform is provided to the students, so
        that they are able to explore themselves and prove themselves true to
        the motto of the school, that is, Dream, Dare and Do. We, at Cambridge,
        value their creative expression and encourage their unique abilities.
      </p>
      <h2 className="mask-h4">Special Educational Needs</h2>
      <p>
        Cambridge Special Needs Program aims to improve through inclusion,
        education and advocacy, the quality of life of differently abled
        children. Learning Wings is an international community of professionals
        committed to meeting the diverse needs of children and creating
        opportunities for students with special educational needs in the
        mainstream. Our program’s vision is to reach out to all learners and
        enable them to receive meaningful and equitable access to the
        curriculum. It is our goal that each student, receive the necessary
        resources, guidance, accommodations, and differentiation to attain their
        highest level of personal success. We value collaboration and shared
        responsibility with students, parents and community partners to create a
        culture of inclusivity, compassion and respect for individual
        differences.
      </p>
    </div>
  </K12SchoolPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
